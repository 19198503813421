import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import './blogPost.css'

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        social={data.site.siteMetadata.social}
        contentType="article"
        articleAuthor={data.site.siteMetadata.author.name}
        articleCategory="Technology"
        //articleModifiedTime=""
        articlePublishedTime={post.frontmatter.date}
        articleImage={post.frontmatter.previewImage}
      />
      <Container maxWidth="md">
          <Box sx={{ my: 4 }}>
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <Typography variant="h2" component="h2" gutterBottom itemProp="headline">
                {post.frontmatter.title}
                </Typography>
                <p>
                  {post.frontmatter.date}
                </p>
                {
                  post.frontmatter.categories ? post.frontmatter.categories.split(";").map((row, index) => (
                    <Link key={index} to={`/categories/${row}`} style={{ textDecoration: "none" }}>
                      <Chip variant="outlined" label={row} style={{ 
                        cursor: "pointer", 
                        marginLeft: "15px", 
                        marginBottom: "20px", 
                        color: "white",
                        backgroundColor: "var(--color-primary)" }} />
                    </Link>
                  )) : null
                }
                <div>
                </div>
              </header>
              <section
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
              />
              <hr />
              <footer>
                <Bio author={data.site.siteMetadata.author} social={data.site.siteMetadata.social} />
              </footer>
            </article>
            <nav className="blog-post-nav">
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
                <li>
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev" style={{ textDecoration: "none" }}>
                      <Chip icon={<ChevronLeftIcon />} label={previous.frontmatter.title} style={{ cursor: "pointer" }} />
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link to={next.fields.slug} rel="next" style={{ textDecoration: "none" }}>
                      <Chip icon={<ChevronRightIcon />} label={next.frontmatter.title} style={{ cursor: "pointer", direction: "rtl", paddingRight: "10px" }} className="nextChip" />
                    </Link>
                  )}
                </li>
              </ul>
            </nav>
          </Box>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        author {
          name
          summary
        }
        social {
          twitter
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        previewImage
        categories
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
